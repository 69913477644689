import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {Currency, FormatNumber, ImagesUrl, ServerUrl, API_URL, Token, config} from '../component/include'

import Select from 'react-select'
import {  displayMonthYear, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'

const StaffSalary =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [paymentList, setPaymentList]=useState([])
    const [payment, setPayment] = useState({
       staff:{label:'', value:'', staffName:''},
       fromDate:'',
        toDate:'',
    })
    const staff  = useSelector(state => state.rootReducer.staff);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    

        
        const handleSelect= (option, action)=>{
            setPayment({...payment, [action.name]:option});
            setErrors({...errors, [action.name]:''})
        }

        const handleChange=(event)=>{
            const {name, value} = event.target;
            setPayment({...payment, [name]:value})
            setErrors({...errors, [name]:''})
            
        }

        const fetchPaymentStaff =()=>{
            setNotice({...notice, isLoading:true})
            const fd = new FormData();
    
            fd.append('staff', payment.staff.value);
            fd.append('fromDate', payment.fromDate);
            fd.append('toDate', payment.toDate);


            let url = API_URL+'/payroll/all_salary_payment';
            axios.post(url, fd,  config).then(result=>{
                if(result.data.data.length!==0){
                    setPaymentList(result.data.data)
                    }
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading:false})
            })
            }

    

        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Salary">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Staff Salary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Staff Salary</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="card-block">
        
        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
        <section className="col-md-6">
   <div className="form-group">
<label >Select Staff </label>
      <Select  options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.fileNo, staffName:list.staffName}
                             })} 
                        getOptionLabel={option =>`${option.label} - ${option.staffName}`}
onChange={handleSelect}  name="staff" value={payment.staff}  /> 
   </div>

</section>
      
      <section className="col-md-3">
      <div className="form-group">
   <label >From Date</label>
   <input type="month" className='form-control' name="fromDate"   onChange={handleChange} value={payment.fromDate} />

               </div>
</section>
      	
      
   <section className="col-md-3">
      <div className="form-group">
   <label >To Date</label>
   <input type="month" className='form-control' name="toDate"   onChange={handleChange} value={payment.toDate} />

               </div>
</section>
</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchPaymentStaff} className="btn btn-primary "><i className="fa fa-search"></i> Search</button>
								</footer>		</section>		
                                </div>
                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>



{paymentList.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table'  key={id}>
       <tbody>
                <tr>
                    <td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/about/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`} style={{ height:'100px',width:'100px'}}  alt={'schoolName'}  />
 </td>
                </tr>
            </tbody>
       </table>  )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>STAFF SALARY </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Date Printed: <b>{longDate(new Date())}</b></td>
                    <td >Month Range: From <b>{longDate(payment.fromDate)+ ' to '+ longDate(payment.toDate)}</b></td>
                </tr>
            </tbody>
       </table>
       <table className="table table-striped table-bordered">
        <thead>
                <tr>
                <th>Staff</th>
                <th>Invoice No</th>
                <th>Paid Month</th>
                <th>Payment Date</th>
                    <th>Payment Mode</th>
                    <th>Amount ({Currency})</th>
                    
                </tr>
              </thead>
              <tbody>
              {paymentList.length!==0?paymentList.map((st, idx)=> 
                <tr key={idx}>
                <td>{st.fileNo+' - '+st.staffName}</td>
                <td>{st.id}</td>
                <td>{displayMonthYear(st.payment_month)}</td>
                <td>{longDate(st.payment_date)}</td>
                <td>{st.payment_mode}</td>
                <td>{FormatNumber(st.total)}</td>
                </tr>):[]}
              </tbody>
           
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">

        <form method='POST' action={ServerUrl+'/printout/staff_salary.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(paymentList, null, 2)} />
                <input type='hidden' name='month' value ={longDate(payment.fromDate)+ ' to '+ longDate(payment.toDate)} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(StaffSalary) 