import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import { config,  allowDelete, allowEdit, API_URL} from '../component/include'
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

 const SalaryStructure =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
 
const [title, setTitle]=useState([])
const [isEditTemplate, setIsEditTemplate] = useState(false);

const [template, setTemplate]=useState([])
const [editTemplate, setEditTemplate]=useState([])


const [master, setMaster] = useState({
    salaryType:[],
    code:'',
    title:'',
    fixedSalary:'',
    templateName:''
})
const [errors, setErrors] = useState({});
const [activeStep, setActiveStep] = useState(1);
const handleOnClickNext = useCallback(() => {
    setActiveStep(activeStep + 1);
   
}, [activeStep])
const handleOnClickStepper = (step) => {
    setActiveStep(step);        
    
}
const handleOnClickBack = useCallback(() => {
    setActiveStep(activeStep - 1);
   
}, [activeStep])

const steps = [{title: 'Salary Title'}, {title: 'Salary Structure'}]


const fetchTitle =()=>{
    let url = API_URL+'/payroll/salary_title/display/all';
    axios.get(url,  config).then(result=>{
        if(result.data.data.length!==0){
            setTitle(result.data.data)
            setTemplate(result.data.template)
            }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }



 const handleChange = event =>{
    const {name, value} = event.target
   setMaster({...master, [name] : value });
   setErrors({...errors, [name]:''})
}





const handleSubmitTemplate = event =>{
    event.preventDefault();  
    
    let errors = {};   
    if(!master.templateName){
        errors.templateName='Please enter template name';
    }
    if(!Array.isArray(master.fixedSalary)){
        errors.fixedSalary ='Please select fixed salary';
       
    }
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){
        submitTemplate()
    }
}


 
const handleSubmitTitle = event =>{
    event.preventDefault();   
    
    let errors = {};   
    if(master.salaryType.length===0){
        errors.salaryType ='Please select salary Head';
    }
    if(!master.title){
        errors.title ='Please select title';
    }
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

        submitTitle()
    }


}



function submitTitle(){ 
   
    setNotice({...notice,  isLoading: true})         
        const fd = new FormData();
        fd.append('salaryHead', master.salaryType.value);
        fd.append('title', master.title);
        fd.append('code', master.code);
        
         let url = API_URL+'/payroll/salary_title/add'
        axios.post(url, fd, config)
        .then(response =>{
                if(response.data.status ===200){
                    Alerts('Saved!', 'success', response.data.message)
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    fetchTitle()
                    setNotice({...notice,  isLoading: false}) 
                     setMaster({...master, salaryType:[], title:'', code:''});
                })
}


const handleDelete=(code, table)=>{
    let close =   document.getElementById('btnWarningDialog-'+code)
    close.click();
    
    
    let url = '';

        if(table==='tbl_salary_title'){
            
    url = API_URL+'/payroll/salary_title/delete/'+code
        }else{
     url = API_URL+'/payroll/salary_template/delete/'+code
        }

    setNotice({...notice, isLoading: true}) 
      axios.get(url, config)
      .then(response =>{
       if(response.data.status ===200){
          Alerts('Saved!', 'default', response.data.message)
              } else{
          Alerts('Error!', 'danger', JSON.stringify(response.data))
              }   
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      }).finally(()=>{
        fetchTitle()
          setNotice({...notice,  isLoading: false  })
      }) 
    
}



const tableSalaryTitleHeader = [

    {dataField: 'salaryHead', text: 'Type', editor: {
        type: Type.SELECT, options:[
            { value: 'DEDUCTION', label: 'DEDUCTION' },
            { value: 'EARNING', label: 'EARNING' }
        ] }},
    {dataField: 'salaryCode', text: 'Code', validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'Code field is required'
          };
        }
        return true;
      }},
    {dataField: 'title', text: 'Salary Title', validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'Salary Title field is required'
          };
        }
        return true;
      }},
      
   {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
       
   {allowDelete==='Yes'?   <a href="#!" className='btn btn-danger btn-sm' data-toggle="modal"   data-target={'#warningdialog-'+row.code}     title="Delete"><i className="icofont icofont-delete-alt"></i></a>:[]}&nbsp;

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code, 'tbl_salary_title')} mID={row.code} /> 
</div>, editable: false, hidden:allowDelete==='No' && allowEdit==='No'?true:false},     
 ];




 const TableBasic=(props)=>{
       
    const handleUpdate=(column, newValue, code)=>{       
        const fd = new FormData();
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('code', code);

        let url = '';

        if(props.table==='tbl_salary_title'){
            
    url = API_URL+'/payroll/salary_title/change_field'
        }else{
     url = API_URL+'/payroll/salary_template/change_field'
        }

       
        axios.post(url, fd, config)
            //.then(result => console.log(result.data))
            .then()
            .catch((error)=>Alerts('Error!', 'danger', error.message))
            .finally(()=>{
                fetchTitle()
            }) 
        } 

        
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                    
                                           
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                            beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.code);
                            
                            return true;
                            }
                            
                        }
                                    }) }
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }



    const handleSelect = (option, action)=>{
        setMaster({...master, [action.name]: option});
        setErrors({...errors, [action.name]:''})
         }



        
    function submitTemplate(){ 
        setNotice({...notice, isLoading: true})
        var options = master.fixedSalary;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
                value.push({titleCode:options[i].value});
            }
    
            const fd = new FormData();
            fd.append('templateName', master.templateName);
            fd.append('template', JSON.stringify(value, null, 2));
            
            let url = API_URL+'/payroll/salary_template/add'
            axios.post(url, fd, config)
            .then(response =>{
                if(response.data.status ===200){
                    Alerts('Saved!', 'success', response.data.message)
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    
                    setNotice({...notice, isLoading: false})
                        fetchTitle()
            setMaster({...master, fixedSalary:[], templateName:''});
                })
    }




const getTitleName = (code, field) =>{ 
    const result = title.filter(list=>list.code===code);
   const answer = result.map((c)=>c[field] ); 
     return  String(answer);
   }
   
const getTemplate=(temp)=>{
    var value = [];
 JSON.parse(temp).map((itm, idx)=>{
        value.push({value:itm.titleCode, label:getTitleName(itm.titleCode, 'salaryCode'), title:getTitleName(itm.titleCode, 'title'), key:idx});
    } );
    
return value;

}

const handleSubmit =()=>{
    setActiveStep(1)
}

const handleChangeTemplate = (e)=>{  
       const value =e.target.value;
   setEditTemplate({...editTemplate, [e.target.name]:value});
   setErrors({...errors, [e.target.name]:''})
}

const handleSelectEditTemplate = (option, action)=>{ 
  var value = [];
        for (var i = 0, l = option.length; i < l; i++) {
          value.push({titleCode:option[i].value});
        }

        setEditTemplate({...editTemplate, template:JSON.stringify(value)});
        setErrors({...errors, [action.name]:''})
}


    

        
    

        const handleEditTemplate =(row)=>{

          setEditTemplate(row)
            setIsEditTemplate(true)
            window.scrollTo(0,0); 
    }
    

const createEditTemplate =()=>{
     return <div className="row">
                                
        <section className="col-md-6">
        <div className="form-group">
            
            <label>Template Name</label>
                    <div className="input-group">
                        <input type="text" placeholder='Template Name' name='templateName' onChange={handleChangeTemplate} value={editTemplate.templateName} className={errors.templateName ? 'form-control form-control-danger' : 'form-control'} />
                        </div>
    
                        <span style={{color:'red'}}>{errors.templateName && errors.templateName}</span>
                    </div>
                    </section>
    
    <section className="col-md-6">
        <label>Fixed Salary</label>
        <div className="form-group">
            <Select isMulti options={
                               title&&title.map((list, idx)=> {
                                return {key:idx, value: list.code, label: list.salaryCode, title:list.title}
                                })}
                                    
                     getOptionLabel={option =>`(${option.label}) ${option.title}`}
    onChange={handleSelectEditTemplate} className={errors.fixedSalary ? 'form-control form-control-danger' : ''} name="template" value={getTemplate(editTemplate.template)}  /> 
    <span style={{color:'red'}}>{errors.fixedSalary&& errors.fixedSalary}</span>
            </div>
    
        </section>
    
        <section className="col-md-6">
                    <br/>
            <div className="form-group">

                
            <button className="btn btn-inverse" onClick={()=>setIsEditTemplate(false)} type="button" >Cancel</button>    &nbsp;&nbsp;
            <button className="btn btn-success" onClick={UpdateTemplate} type="button" >Update</button> 
              
            </div>
        </section>
            </div>
}





function UpdateTemplate(){ 
   if(editTemplate.templateName===''){
       setErrors({...errors, templateName:'Please enter template Name'})
   }else if(editTemplate.template.length ===0){
       
    setErrors({...errors, fixedSalary:'Please select at lease one Head'})
   }else{
    setNotice({...notice, isLoading: true})      
        
        let formData = editTemplate;
        const fd = new FormData();
        for(let k in formData){
            fd.append(k,formData[k]);
        };
         let url = API_URL+'/payroll/salary_template/update/'+editTemplate.code
        axios.post(url, fd, config)
        .then(response =>{
           if(response.data.status ===200){
            Alerts('Update!', 'default', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            
            setIsEditTemplate(false)
            setNotice({...notice, isLoading: false})
            fetchTitle()

        })       
   }
}



   




   

    

     const getTitle = (code) =>{ 
        const result = title.filter(list=>list.code===code);
       const answer = result.map(c=>`(${c.salaryCode}) ${c.title}`); 
         return  answer;
       }

     const handleSalaryTitle=(cell, row)=>{
        return JSON.parse(cell).map((itm, idx)=>{
            return <div  key={idx}>{getTitle(itm.titleCode)}</div>
        } );
    }
    

     const tableSalaryTemplateHeader = [
       
        {dataField: 'templateName', text: 'Template Name',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Template  field is required'
              };
            }
            return true;
          } } ,
          {dataField: 'template', text: 'Fixed Salary', formatter: handleSalaryTitle, sort: true,  editable: false},
          {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>

          {allowDelete==='Yes'? <a href="#!" data-toggle="modal"   data-target={'#warningdialog-'+row.code}  className='btn btn-danger btn-sm'   title="Delete"><i className="icofont icofont-delete-alt"></i></a>:[]}&nbsp;&nbsp; 
          
       
          {allowEdit==='Yes'?  <a href="#!" className='btn btn-warning btn-sm'  onClick={()=>handleEditTemplate(row)}   title="Edit"> <i className="icofont icofont-edit-alt"></i></a>:[]}
                
               
       <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code, 'tbl_salary_template')} mID={row.code} />
               </div>, editable: false, hidden:allowDelete==='No' && allowEdit==='No'?true:false},    
     ];



     
useEffect(()=>{
    fetchTitle()
 },[]);

        return (  
   <> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

        <PageHeader title="Payroll">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Salary Title & Structure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Salary Title & Structure</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="position">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
   <div className="row">
                                
    <section className="col-md-6">
    <div className="form-group">
        
        <label>Salary Title </label>
                <div className="input-group">
                    <input type="text" placeholder='Salary Title' name='title' onChange={handleChange} value={master.title} className={errors.title ? 'form-control form-control-danger' : 'form-control'} />
                    </div>

                    <span style={{color:'red'}}>{errors.title && errors.title}</span>
                </div>


    <label>Salary Type</label>
    <div className="form-group">
        <Select  options={[
                    { value: 'DEDUCTION', label: 'DEDUCTION' },
                    { value: 'EARNING', label: 'EARNING' },
                ]}  
onChange={handleSelect} className={errors.salaryType ? 'form-control form-control-danger' : ''} name="salaryType" value={master.salaryType}  /> 
<span style={{color:'red'}}>{errors.salaryType&& errors.salaryType}</span>
        </div>


       
    </section>

    <section className="col-md-6">
    <div className="form-group">
        
        <label>Code</label>
                <div className="input-group">
                    <input type="text" placeholder='Code' name='code' onChange={handleChange} value={master.code} className='form-control' />
                    </div>
                </div><br/>
        <div className="form-group">
            
        <button className="btn btn-inverse" onClick={handleSubmitTitle} type="button" >Add</button>                  
        </div>
    </section>


        </div>
                               </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <TableBasic data={title}  table={'tbl_salary_title'} columns={tableSalaryTitleHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>
	  
	 
      :   <div className="row setup-content" id="structure">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
 {isEditTemplate?createEditTemplate():     
  <div className="row">
                                
        <section className="col-md-6">
        <div className="form-group">
            
            <label>Template Name</label>
                    <div className="input-group">
                        <input type="text" placeholder='Template Name' name='templateName' onChange={handleChange} value={master.templateName} className={errors.templateName ? 'form-control form-control-danger' : 'form-control'} />
                        </div>
    
                        <span style={{color:'red'}}>{errors.templateName && errors.templateName}</span>
                    </div>
                    </section>
    
    <section className="col-md-6">
        <label>Fixed Salary</label>
        <div className="form-group">
            <Select isMulti options={
                                title&&title.map((list, idx)=> {
                                    return {key:idx, value: list.code, label: list.salaryCode, title:list.title}
                                    })}
                                    
                     getOptionLabel={option =>`(${option.label}) ${option.title}`}
    onChange={handleSelect} className={errors.fixedSalary ? 'form-control form-control-danger' : ''} name="fixedSalary" value={master.fixedSalary}  /> 
    <span style={{color:'red'}}>{errors.fixedSalary&& errors.fixedSalary}</span>
            </div>
    
        </section>
    
        <section className="col-md-6">
                    <br/>
            <div className="form-group">
                
            <button className="btn btn-inverse" onClick={handleSubmitTemplate} type="button" >Add</button>                  
            </div>
        </section>
            </div>}
                              </div>
                              
      </div>	
            <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12 table-responsive">
      {notice.isDataFetching ? <TableLoader />:
   <TableBasic data={template}  table={'tbl_salary_template'} columns={tableSalaryTemplateHeader }  />} 
    </section>
              </div>
              </div>
      
      </div>	</div>
      
      </div>	
           
      </div>
   
</div>}

                <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(SalaryStructure) 