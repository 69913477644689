import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, FormatNumber, Currency,  config, allowDelete, allowEdit, API_URL, staffCode} from '../component/include'
import Select from 'react-select'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {longDate, displayMonthYear} from '../component/globalFunction'
import { userToken } from '../component/authentication'

const BulkSalaryPayment =()=> {
    const [salaryStructure, setSalaryStructure]=useState([]);

    const [title, setTitle]=useState([])
    const [template, setTemplate]=useState([])
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [paymentList, setPaymentList]=useState([])
    const [staffList, setStaffList]=useState([])
    const [payment, setPayment] = useState({
        template:[],
        payment_mode:[],
        payMonth:'',
        payDate:'',
        account:[],
        remarks:''
    })

    
        
        const fetchTemplate =()=>{
            let url = API_URL+'/payroll/salary_title/display/all';
            axios.get(url,  config).then(result=>{
                if(result.data.data.length!==0){
                    setTitle(result.data.data)
                    setTemplate(result.data.template)
                    }
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }

            const fetchPaymentStaff =(month)=>{
                setNotice({...notice, isLoading:true})
                let url = API_URL+'/payroll/staff_salary_payment/'+month;
                axios.get(url,  config).then(result=>{
                    if(result.data.data.length!==0){
                        setStaffList(result.data.data)
                        }
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    setNotice({...notice, isLoading:false})
                })
                }


            const fetchHistory =()=>{
                let url = API_URL+'/payroll/staff_salary_history';
                axios.get(url,  config).then(result=>{
                    if(result.data.data.length!==0){
                        setPaymentList(result.data.data)
                        setStaffList([])
                        }
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                })
                }

    



const handleChange=(event)=>{        
    const {name, value}=event.target
    setPayment({...payment, [name]:value})
    setErrors({...errors, [name]:''})
}




const handleSelect= (option, action)=>{

    setPayment({...payment, [action.name]:option});; 
    setErrors({...errors, [action.name]:''})

    if(action.name==='template'){          
        fetchSalaryStructure(option.value);
        }
}


const getTitle = (code, field) =>{ 
    const result = title.filter((list)=>list.code===code);
   const answer = result.length!==0?result[0][field]:'' 
     return  answer;
   }



const  fetchSalaryStructure =async(template_code)=>{    
       
    setNotice({...notice, isLoading: true}) 

    //get all the staff that is using this template
    let url = API_URL+'/payroll/assign_structure/get_staff/'+template_code
  await  axios.get(url, config).then((result)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
        try{

            const staffList = result.data.data

            
            var allStaff = [];
            for (var k = 0, m = staffList.length; k < m; k++) {


         let options = JSON.parse(staffList[k].salary)
       
            for (var i = 0, l = options.length; i < l; i++) {
              allStaff.push({
                unique_code:Math.random().toString(36).substring(2, 9),
                code:options[i].code, 
                title:getTitle(options[i].code, 'title'),
                 amount:options[i].amount,
                 salaryCode:getTitle(options[i].code, 'salaryCode'),
                 salaryHead:getTitle(options[i].code, 'salaryHead'),
                 amount_topay:options[i].amount, 
                 paid:options[i].amount, 
                 balance:0,
                 template_code:template_code,
                 staff:staffList[k].staff
                
                });
            }
    
        }
        setSalaryStructure(allStaff) 

        }catch(e){

        }
     
      }else{
        setSalaryStructure([])
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false}) 
    }) 
} 



    const handleReset =()=>{
        setPayment({
            template:[],
            payment_mode:[],
            payMonth:'',
            payDate:'',
            account:[],
            remarks:''
        })
    } 



    const handleDelete=(unique_code)=>{
        let close =   document.getElementById('btnWarningDialog-'+unique_code)
        close.click();
        
        
        let url = API_URL+'/payroll/salary_payment/delete/'+unique_code
    
    
        setNotice({...notice, isLoading: true}) 
          axios.get(url, config)
          .then(response =>{
           if(response.data.status ===200){
              Alerts('Saved!', 'default', response.data.message)
                  } else{
              Alerts('Error!', 'danger', JSON.stringify(response.data))
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            fetchHistory()
              setNotice({...notice,  isLoading: false  })
          }) 
        
    }
   




    const tableStaffHeader = [
        
        {dataField: 'staffName', text: 'Staff', editable:false},

        {dataField: 'earning', text: 'Earning', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },

        {dataField: 'deduction', text: 'Deduction', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },

        {dataField: 'total', text: 'Net Pay', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },
        {dataField: 'payment_month', text: 'Month',  editable:false, formatter:(cell)=>displayMonthYear(cell)},       
        {dataField: 'payment_date', text: 'Date Paid', editable:false, formatter:(cell)=>longDate(cell)} 
     ];

    const tableHeader = [
        {dataField: 'unique_code', text: 'Action', formatter: (cell, row)=><div>
            
            <button type='button' onClick={()=>fetchPaymentStaff(row.payment_month)} className='btn btn-dark btn-sm'><i className="fa fa-eye"></i> View Staff</button>&nbsp;
            {allowDelete==='Yes'? <a href="#!" data-toggle="modal" className="btn btn-sm btn-danger btn-sm"  data-target={'#warningdialog-'+row.unique_code}     title="Revert Salary Payment"><i className="fa fa-mail-reply"></i></a>:[]}

        <WarningModal message="This will revert all salary payment for the month. Are you really sure?" handleAction={()=>handleDelete(row.unique_code)} mID={row.unique_code} /> 
        </div>, editable: false},
        
        {dataField: 'templateName', text: 'Stucture', editable:false},

        {dataField: 'earning', text: 'Earning', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },

        {dataField: 'deduction', text: 'Deduction', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },

        {dataField: 'total', text: 'Net Pay', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },
        {dataField: 'payment_month', text: 'Month',  editable:false, formatter:(cell)=>displayMonthYear(cell)},       
        {dataField: 'payment_date', text: 'Date Paid', editable:false, formatter:(cell)=>longDate(cell)} 
     ];


    
 const TableBasic=(props)=>{
       
        
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='unique_code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             <div className="dt-buttons btn-group pull-right"> 


                            
            <form method='POST' action={ServerUrl+'/printout/staff_bulk_salary_list.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(staffList, null, 2)} />
               
                <input type='hidden' name='jwtToken' value ={Token} />
                <button type="submit" className="btn btn-outline-primary" > <i className="fa fa-print"></i>Print Preview</button>
        
        </form>

</div>        
                                           
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                   
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }


   
  const handleSubmit =(event)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(salaryStructure.length===0){
    error.template ='Please assign staff to this template';
    formIsValid = false;
} 

if(payment.template.length===0){
    error.template =msg;
    formIsValid = false;
} 

if(payment.payment_mode.length===0){
    error.payment_mode =msg;
    formIsValid = false;
}  
 
if(!payment.payMonth){
    error.payMonth =msg;
    formIsValid = false;
}  

if(!payment.payDate){
    error.payDate =msg;
    formIsValid = false;
}  

if(!formIsValid){
setErrors(error) 

window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
}

if(formIsValid) {
    setNotice({...notice, 
        isLoading: true}) 
   
    const fd = new FormData();
    let code = 'SP'+Math.random().toString(36).substring(2, 9)

    fd.append('structure', JSON.stringify(salaryStructure, null, 2)); 


    fd.append('unique_code', code);
    fd.append('added_by', staffCode);
    fd.append('structure_code', payment.template.value);
    fd.append('payment_mode', payment.payment_mode.value);
    fd.append('payment_month', payment.payMonth);
    fd.append('payment_date', payment.payDate);
    fd.append('remarks', payment.remarks);
let url = API_URL+'/payroll/auto_salary_payment/add_payment';
  axios.post(url, fd, config)
  .then(response =>{
   if(response.data.status === 200){
    Alerts('success', 'success', response.data.message)
} else{
  Alerts('danger', 'danger', response.data.message)
        
              }  
          })
          .catch((error)=>{
          Alerts('Error', 'danger', error.message)
         
          }).finally(()=>{
        handleReset()
        fetchHistory()
        setNotice({...notice, 
            isLoading: false}) 
          })  
    }

}
 
useEffect(()=>{
fetchTemplate()
fetchHistory()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Bulk Salary Payment">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Salary Payment</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div  className="row  ">   
<div className="col-sm-12">                                                                  
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Staff Salary Payment List</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
   <div className="form-group">
<label >Select Template <span style={{color:'red'}}>*</span></label>
      <Select options={
            template.map((list, idx)=> {
                return {key:idx, value: list.code, label: list.templateName}
                })} 
                    onChange={handleSelect} 
                    className={errors.template ? 'form-control form-control-danger' : ''} name="template"
                        value={payment.template}  /> 

<span style={{color:'red'}}>{errors.staff && errors.staff}</span>
   </div>


   <div className="form-group">
<label >Payment Mode <span style={{color:'red'}}>*</span></label>
      <Select options={[
                                    { value: 'CASH', label: 'CASH' },
                                    { value: 'CHEQUE', label: 'CHEQUE'},
                                    { value: 'TRANSFER', label: 'TRANSFER'},
                                    { value: 'BANK', label: 'BANK'},
                                    { value: 'OTHERS', label: 'OTHERS'},
                                ]} 
onChange={handleSelect} className={errors.paymentType ? 'form-control form-control-danger' : ''} name="payment_mode" value={payment.payment_mode}  /> 
<span style={{color:'red'}}>{errors.paymentType && errors.paymentType}</span>
   </div>
</section>



<section className="col-md-6">
   

   <div className="form-group">
<label >Payment Month <span style={{color:'red'}}>*</span></label>
<input type="month" className={errors.payMonth ? 'form-control form-control-danger' : 'form-control'} name="payMonth"  onChange={handleChange} value={payment.payMonth} />
<span style={{color:'red'}}>{errors.payMonth && errors.payMonth}</span>
   </div>


   <div className="form-group">
<label >Payment Date <span style={{color:'red'}}>*</span></label>
<input type="date" className={errors.payDate ? 'form-control form-control-danger' : 'form-control'} name="payDate"  onChange={handleChange} value={payment.payDate} />
<span style={{color:'red'}}>{errors.payDate && errors.payDate}</span>
   </div>
</section>

<div className="col-md-12">
             <div className="form-group">
            <label className="col-form-label">Remarks</label>
           
            <input type="text" className="form-control form-control-lg"  name='remarks' value={payment.remarks} onChange={handleChange}  />
          
             </div>

             </div>

				</div>	
                

                            
<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" onClick={()=>handleReset()} className="btn btn-inverse "><i className="fa fa-refresh"></i> Reset</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleSubmit}  className={'btn btn-success'}><i className="fa fa-save"></i>Generate Payment</button>
								</footer>		</section>		
							</div>
                            
                            
                            </div> </div> 
							
</div>
</div> </div>    </div>
                            
                            </div>
</div>

<div  className="row  ">   
<div className="col-sm-12">                                                                  
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Staff Salary History</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

          {notice.isDataFetching ? <TableLoader />:
      <TableBasic data={paymentList}   columns={tableHeader}  />}  

            </div></div>
    
    </div>
    
    </div>
    </div>




{staffList.length!==0?

<div  className="row  ">   
<div className="col-sm-12">                                                                  
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Staff Salary </h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

          {notice.isDataFetching ? <TableLoader />:
      <TableBasic data={staffList}   columns={tableStaffHeader}  />}  

            </div></div>
    
    </div>
    
    </div>
    </div>:''
    }

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(BulkSalaryPayment) 